<template>
  <v-app>
    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="pl-0">{{ $route.meta.pageName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <span class="hidden-xs-only error--text">{{ employee.full_name }}</span>

      <v-menu
        offset-y
        bottom
        left
        :close-on-content-click="false"
        v-model="notifyMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            small
            v-bind="attrs"
            v-on="on"
            class="mx-2"
            color="white"
          >
            <v-badge
              bordered
              overlap
              color="error"
              :content="notifyCount"
              v-if="notifyCount > 0"
            >
              <v-icon>mdi-bell-outline</v-icon>
            </v-badge>
            <v-icon v-else>mdi-bell-outline</v-icon>
          </v-btn>
        </template>
        <v-card
          class="mx-auto"
          max-width="350"
          min-width="300"
          max-height="500"
        >
          <NotifyList v-if="notifyMenu" @onNotifyChange="onNotifyChange" />
        </v-card>
      </v-menu>

      <!--<v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed v-bind="attrs" v-on="on" small color="white" class="px-1">
                  <v-icon>mdi-translate</v-icon>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item class="cursor-pointer" @click="changeLanguage('vi')" :class="{'primary white--text': $i18n.locale === 'vi'}">
                  <v-list-item-title>Tiếng Việt</v-list-item-title>
                </v-list-item>
                <v-list-item class="cursor-pointer" @click="changeLanguage('en')" :class="{'primary white--text': $i18n.locale === 'en'}">
                  <v-list-item-title>English</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>-->

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            v-bind="attrs"
            v-on="on"
            small
            color="white"
            class="px-1"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item v-if="isMobileAgent()">
            <v-list-item-title>{{ employee.full_name }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showDialogChangePassword">
            <v-list-item-title>{{
              $t("labels.change_password")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="spto" @click="backToAdmin">
            <v-list-item-title>Quay lại tài khoản Admin</v-list-item-title>
          </v-list-item>
          <v-list-item v-else @click="logout">
            <v-list-item-title>{{ $t("labels.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-toolbar elevation="4" light>
        <a href="/notification">
          <v-img
            height="35"
            contain
            :src="require('@/assets/common/logo.png')"
          ></v-img>
        </a>
      </v-toolbar>

      <v-divider></v-divider>

      <template v-if="posRows.length > 0">
        <v-divider></v-divider>

        <v-list dense>
          <div v-for="item in posRows" :key="item.title">
            <template v-if="checkMenuItem(item)"
              ><!--  -->
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                    :disabled="!checkMenuItem(child) || child.disabled"
                    ><!--  -->
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ child.title }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
                :disabled="!checkMenuItem(item) || item.disabled"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-dialog v-model="dialogChangePassword" persistent max-width="350px">
      <v-card v-if="dialogChangePassword">
        <v-card-title class="text-h5 mb-3">{{
          $t("labels.change_password")
        }}</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="oldPassword"
            class="c-input-small mb-3"
            :label="$t('labels.old_password')"
            :type="passwordShow ? 'text' : 'password'"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="off"
            @click:append="passwordShow = !passwordShow"
            autocapitalize="off"
            autofocus
            dense
            outlined
            hide-details
          ></v-text-field>

          <v-text-field
            v-model="newPassword"
            class="c-input-small mb-3"
            :label="$t('labels.new_password')"
            :type="passwordShow ? 'text' : 'password'"
            :disabled="!oldPassword"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="off"
            @click:append="passwordShow = !passwordShow"
            autocapitalize="off"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideDialogChangePassword">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="changePassword"
            :disabled="!oldPassword || !newPassword || newPassword.length < 6"
          >
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- audio list -->
    <audio id="error_sound_player">
      <source type="audio/mpeg" src="@/assets/audio/error.wav" />
    </audio>
    <audio id="success_sound_player">
      <source type="audio/mpeg" src="@/assets/audio/success.wav" />
    </audio>

    <audio id="DonDaHuy_player">
      <source type="audio/mpeg" src="@/assets/audio/DonDaHuy.wav" />
    </audio>
    <audio id="DonDaQuet_player">
      <source type="audio/mpeg" src="@/assets/audio/DonDaQuet.wav" />
    </audio>
    <audio id="KhongCoQuyenXuLy_player">
      <source type="audio/mpeg" src="@/assets/audio/KhongCoQuyenXuLy.wav" />
    </audio>
    <audio id="MaDonKhongTonTai_player">
      <source type="audio/mpeg" src="@/assets/audio/MaDonKhongTonTai.wav" />
    </audio>
    <audio id="NhamVanChuyen_player">
      <source type="audio/mpeg" src="@/assets/audio/NhamVanChuyen.wav" />
    </audio>
    <audio id="NhamSotRoi_player">
      <source type="audio/mpeg" src="@/assets/audio/NhamSotRoi.wav" />
    </audio>
    <audio id="NhamDonRoi_player">
      <source type="audio/mpeg" src="@/assets/audio/NhamDonRoi.wav" />
    </audio>
    <audio id="TrangThaiKhongHopLe_player">
      <source type="audio/mpeg" src="@/assets/audio/TrangThaiKhongHopLe.wav" />
    </audio>
    <audio id="ChoBienBanBanGiao_player">
      <source type="audio/mpeg" src="@/assets/audio/ChoBienBanBanGiao.wav" />
    </audio>
    <audio id="LoiNhamSPNhapKhoSPDanUID_player">
      <source
        type="audio/mpeg"
        src="@/assets/audio/LoiNhamSPNhapKhoSPDanUID.wav"
      />
    </audio>
  </v-app>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "App",

  components: {
    NotifyList: () => import("@/components/common/NotifyList"),
  },

  data: () => ({
    drawer: false,
    activeItem: "LayoutMain",
    items: [],
    posItems: [
      {
        title: "Chấm công",
        img: require("@/assets/sidebars/timetable.png"),
        route: "Timekeeping",
        permissions: [],
        mobileCheck: true,
      },
      {
        title: "Thông báo",
        img: require("@/assets/sidebars/push_notification.png"),
        route: "Notification",
        active: false,
        permissions: [],
      },
      {
        title: "Nhập kiểm",
        img: require("@/assets/sidebars/pos_nhap_kiem.png"),
        permissions: [
          "pos_goods_receipt_normal",
          "pos_goods_receipt_stowing",
          "pos_goods_receipt_uid",
          "pos_goods_receipt_old_transfer",
          "pos_goods_receipt_old_transfer",
          "pos_goods_receipt_old_stamp",
          "pos_goods_generate_stamp_again",
        ],
        children: [
          /* {
                        title: 'Nhập nhiều',
                        route: 'PosGoodsReceiptDirectMultiple',
                        appendImg: require('@/assets/sidebars/pos_nhap_nhieu.png'),
                        permissions: ['pos_goods_receipt'],
                    },
                    {
                        title: 'Nhập lẻ',
                        route: 'PosGoodsReceiptDirectOne',
                        appendImg: require('@/assets/sidebars/pos_nhap_le.png'),
                        permissions: ['pos_goods_receipt'],
                    }, */
          {
            title: "Thường",
            route: "GoodsReceiptNormal",
            appendImg: require("@/assets/sidebars/pos_nhap_kiem.png"),
            permissions: ["pos_goods_receipt_normal"],
          },
          /* {
                        title: 'Lên kệ',
                        appendImg: require('@/assets/sidebars/lenke.png'),
                        route: 'GoodsReceiptStowing',
                        active: false,
                        permissions: ['pos_goods_receipt_stowing']
                    }, */
          {
            title: "SP. Dán UID",
            route: "PosGoodsReceiptHasUid",
            appendImg: require("@/assets/sidebars/pos_nhap_tu_kho.png"),
            permissions: ["pos_goods_receipt_uid"],
          },
          {
            title: "SSC lần đầu",
            route: "PosGoodsReceiptOldTransfer",
            appendImg: require("@/assets/sidebars/pos_nhap_nhieu.png"),
            permissions: ["pos_goods_receipt_old_transfer"],
          },
          {
            title: "In tem lẻ",
            route: "PosGoodsReceiptOldStamp",
            appendImg: require("@/assets/sidebars/pos_nhap_le.png"),
            permissions: ["pos_goods_receipt_old_stamp"],
          },
          {
            title: "In lại tem",
            route: "PosGenerateStampAgain",
            appendImg: require("@/assets/sidebars/generate_pdf.png"),
            permissions: ["pos_goods_generate_stamp_again"],
          },
        ],
      },
      {
        title: "Chuẩn bị hàng",
        img: require("@/assets/sidebars/pos_chuan_bi.png"),
        route: "PosGoodsSetAction",
        permissions: ["pos_goods_set_action"],
      },
      {
        title: "Xuất hàng lỗi",
        img: require("@/assets/sidebars/pos_xuathangloi.png"),
        route: "PosGoodsExportDefective",
        permissions: ["pos_goods_export_defective"],
      },
      {
        title: "Vị trí",
        img: require("@/assets/sidebars/goods_location_history.png"),
        permissions: ["pos_goods_set_position", "pos_goods_set_position_free"],
        children: [
          {
            title: "Gán Sọt → Vị trí",
            appendImg: require("@/assets/sidebars/pos_len_ke.png"),
            route: "PosGoodsSetPosition",
            permissions: ["pos_goods_set_position"],
          },
          {
            title: "Gán SP → Vị trí",
            appendImg: require("@/assets/sidebars/pos_trung_bay.png"),
            route: "PosGoodsSetPositionFree",
            permissions: ["pos_goods_set_position_free"],
          },
        ],
      },
      {
        title: "Pickup",
        img: require("@/assets/sidebars/pos_pickup.png"),
        permissions: ["pos_goods_pickup"],
        children: [
          {
            title: "Tùy chọn",
            route: "PosGoodsPickupNormal",
            appendImg: require("@/assets/sidebars/pos_pickup_normal.png"),
            permissions: ["pos_goods_pickup"],
          },
          {
            title: "Trưng bày",
            route: "PosGoodsPickupDisplay",
            appendImg: require("@/assets/sidebars/pos_pickup_trung_bay.png"),
            permissions: ["pos_goods_pickup"],
          },
        ],
      },
      {
        title: "Đơn online",
        img: require("@/assets/sidebars/order_list.png"),
        permissions: [
          "pos_order_online_list",
          "pos_order_online_detail",
          "pos_order_online_pickup",
          "pos_order_online_packing",
          "pos_order_online_handover",
          "pos_order_online_return_receive",
          "pos_order_online_return_import",
          "pos_order_online_return_stowing",
          "pos_order_online_return_history",
        ],
        children: [
          {
            title: "Pickup",
            route: "PosOrderOnlinePickup",
            appendImg: require("@/assets/sidebars/pickup.png"),
            permissions: ["pos_order_online_pickup"],
          },
          {
            title: "Đóng gói",
            route: "PosOrderOnlinePacking",
            appendImg: require("@/assets/sidebars/donggoi.png"),
            permissions: ["pos_order_online_packing"],
          },
          {
            title: "Bàn giao",
            route: "PosOrderOnlineHandover",
            appendImg: require("@/assets/sidebars/bangiaovanchuyen.png"),
            permissions: ["pos_order_online_handover"],
          },
          {
            title: "Nhận hoàn",
            route: "PosOrderOnlineReturnReceive",
            appendImg: require("@/assets/sidebars/nhanhoan.png"),
            permissions: ["pos_order_online_return_receive"],
          },
          {
            title: "Nhập hoàn",
            route: "PosOrderOnlineReturnImport",
            appendImg: require("@/assets/sidebars/nhaphoan.png"),
            permissions: ["pos_order_online_return_import"],
          },
          /* {
                        title: 'Lên kệ hàng hoàn',
                        route: 'PosOrderOnlineReturnStowing',
                        appendImg: require('@/assets/sidebars/lenke.png'),
                        permissions: ['pos_order_online_return_stowing']
                    }, */
          {
            title: "Quản lý đơn",
            route: "PosOrderOnlineList",
            appendImg: require("@/assets/sidebars/order_list.png"),
            permissions: ["pos_order_online_list"],
          },
          {
            title: "Chi tiết đơn online",
            appendImg: require("@/assets/sidebars/quanlyxuatkho.png"),
            route: "PosOrderOnlineDetail",
            permissions: ["pos_order_online_detail"],
          },
          {
            title: "QL. Hoàn",
            appendImg: require("@/assets/sidebars/quanlyhoan.png"),
            route: "PosOrderOnlineReturnHistory",
            permissions: ["pos_order_online_return_history"],
          },
        ],
      },
      {
        title: "Bán hàng",
        img: require("@/assets/sidebars/pos_ban_hang.png"),
        route: "PosOrderCreate",
        permissions: ["pos_order_create"],
        desktopCheck: true,
      },
      {
        title: "Yêu cầu xử lý",
        img: require("@/assets/sidebars/order_request.png"),
        route: "InternalRequest",
        active: false,
        permissions: ["pos_internal_request"],
      },
      {
        title: "Tìm hàng",
        img: require("@/assets/sidebars/timhang.png"),
        route: "GoodsFind",
        active: false,
        permissions: [],
        mobileCheck: true,
        elCountName: "count_find_goods",
      },
      {
        title: "Yêu cầu tìm hàng",
        img: require("@/assets/sidebars/yeucautimhang.png"),
        route: "GoodsFindManager",
        active: false,
        permissions: ["find_goods_manager", "warehouse_admin"],
      },
      {
        title: "Thống kê",
        img: require("@/assets/sidebars/thongkenhanh.png"),
        route: "Dashboard",
        active: false,
        permissions: ["pos_dashboard_manager"],
      },
      {
        title: "QL. Nhập",
        img: require("@/assets/sidebars/receipt_history.png"),
        route: "GoodsReceiptHistory",
        permissions: ["pos_goods_receipt_history"],
      },
      {
        title: "QL. Hàng",
        route: "PosGoodsList",
        img: require("@/assets/sidebars/goods_list.png"),
        permissions: ["pos_goods_manager"],
      },
      {
        title: "QL. UID",
        route: "PosGoodsUid",
        img: require("@/assets/sidebars/pos_uid.png"),
        permissions: ["pos_goods_uid_manager"],
      },
      {
        title: "Vị trí UID",
        route: "PosGoodsUidLocation",
        img: require("@/assets/sidebars/pos_uid.png"),
        permissions: ["pos_goods_uid_location"],
      },
      {
        title: "Thống kê bán hàng",
        route: "PosOrderList",
        img: require("@/assets/sidebars/pos_quan_ly_don.png"),
        permissions: ["pos_order_list"],
      },
      {
        title: "QL. Nhân viên",
        img: require("@/assets/sidebars/hr.png"),
        route: "Employee",
        permissions: ["pos_employee_manager"],
      },
      {
        title: "Quản trị",
        img: require("@/assets/sidebars/quantri.png"),
        route: "Administration",
        active: false,
        permissions: [
          "pos_admin_package",
          "pos_admin_bill",
          "pos_admin_payroll",
          "pos_admin_timekeeping",
        ],
      },
      {
        title: "In tem",
        img: require("@/assets/sidebars/generate_pdf.png"),
        route: "GoodsPrintStamp",
        permissions: ["pos_download_goods_stamp"],
        desktopCheck: true,
      },
      {
        title: "QL. Vật tư",
        img: require("@/assets/sidebars/supplies.png"),
        route: "Packaging",
        active: false,
        permissions: ["pos_packaging_manager"],
      },
    ],
    employee: {},
    notifyCount: 0,
    notifyMenu: false,
    dialogChangePassword: false,
    oldPassword: null,
    newPassword: null,
    passwordShow: false,
    spto: null,
  }),

  computed: {
    posRows() {
      const rows = [];
      this.posItems.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
  },
  watch: {},
  mounted() {
    window.socket.on("auth", (data) => {
      console.log(data);
    });
    window.socket.emit("auth", window.me.auth_key, "employee");

    this.spto = localStorage.getItem("_spto");
    const { employee, notify } = window.me;
    this.employee = { ...employee };
    this.notifyCount = notify;
    // this.listenSocket()

    this.$root.$on("playErrorAudio", this.playErrorAudio);
    this.$root.$on("playSuccessAudio", this.playSuccessAudio);
    this.$root.$on("playAudioByName", this.playAudioByName);
  },
  beforeDestroy() {
    this.$root.$off("playErrorAudio", this.playErrorAudio);
    this.$root.$off("playSuccessAudio", this.playSuccessAudio);
    this.$root.$off("playAudioByName", this.playAudioByName);
  },
  methods: {
    showDialogChangePassword() {
      this.dialogChangePassword = true;
    },

    hideDialogChangePassword() {
      this.dialogChangePassword = false;
      this.oldPassword = null;
      this.newPassword = null;
      this.passwordShow = false;
    },
    async changePassword() {
      if (!this.oldPassword || !this.newPassword) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/update-password", {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        });
        this.hideDialogChangePassword();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    checkMenuItem(item) {
      return (
        (!item.permissions ||
          item.permissions.length === 0 ||
          this.checkPermission(item.permissions)) &&
        this.checkMenuMobile(item.mobileCheck) &&
        this.checkMenuDesktop(item.desktopCheck)
      );
    },
    async logout() {
      await httpClient.post("/leave-table");
      localStorage.removeItem("_spt");
      localStorage.removeItem("_spto");
      localStorage.removeItem("_pktb");
      window.location = process.env.VUE_APP_V2_HOME_PATH;
    },
    checkRoute(routeName) {
      if (this.$route.name === routeName) {
        window.location.reload();
      }
    },
    onNotifyChange(count) {
      this.notifyCount = count;
    },

    listenSocket() {
      window.socket.on("notification_push", (data) => {
        this.notifyCount += 1;
        this.$vToastify.warning(`Bạn có thông báo mới! "${data.title}"`);
      });
    },

    backToAdmin() {
      localStorage.setItem("_spt", this.spto);
      localStorage.removeItem("_spto");
      window.location.reload();
    },

    playErrorAudio() {
      document.getElementById("error_sound_player").play();
    },
    playSuccessAudio() {
      document.getElementById("success_sound_player").play();
    },
    playAudioByName(name) {
      document.getElementById(`${name}_player`).play();
    },
  },
};
</script>
